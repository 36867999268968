<template>
  <a-row type="flex">
    <a-col :span="19">
      <div class="establishment-menu xpress-selection">
        <a-row type="flex">
          <a-col :flex="12" class="selected-menu">
            <a-select
              style="width: 80%"
              @change="changedMenu"
              :default-value="selectedMenu"
              v-if="loaded"
            >
              <a-select-option
                v-for="(section, index) in sections"
                v-bind:key="index"
                :value="index"
                :class="
                  selectedMenu == index
                    ? 'ant-select-dropdown-menu-item-selected'
                    : ''
                "
                :aria-selected="selectedMenu == index ? 'true' : ''"
              >
                {{ section.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <div class="empty-menu-legend" v-if="isEmptyMenu">
          Menú vacío
        </div>
        <a-collapse :expand-icon-position="expandIconPosition">
          <a-collapse-panel
            v-for="(element, index) in menuEstablishment"
            :key="index"
            :header="element.name"
          >
            <div
              class="product-container"
              v-for="(product, index) in element.products"
              :key="index"
            >
              <div class="product">
                <p class="product-name">{{ product.name }}</p>
                <a-row type="flex">
                  <a-col :flex="3">
                    <p class="product-description">{{ product.description }}</p>
                    <p class="product-pricing">{{ isProductWithPrice(product) ?  `$ ${getPricing(product)}` : '--' }}</p>
                  </a-col>
                  <a-col
                    class="product-stock order-menu"
                    v-if="productAvailable(product)"
                    :flex="3"
                  >
                    <p @click="selectExtras(product)">Agregar al carrito</p>
                  </a-col>
                  <a-col class="product-out-stock order-menu" v-else :flex="3">
                    <p>agotado</p>
                  </a-col>
                </a-row>
              </div>
              <a-divider />
            </div>
          </a-collapse-panel>
        </a-collapse>
        <PickExtras
          :option="option"
          :isElectronXpress="isElectronXpress"
          :visible="isPickExtrasVisible"
          @add="addExtras"
          @close="visible = false"
          :key="keyComponent"
        />
      </div>
    </a-col>
    <a-col :span="5">
      <div class="xpress-order">
        <div class="xpress-details">
          <div class="order-content">
            <p>Carrito</p>
            <template v-if="product.length === 0">
              <p class="order-empty">Carrito vacío...</p>
            </template>
            <div
              v-for="(prod, index) in product"
              class="order-selected"
              :key="index"
            >
              <div class="order-name">
                <span class="order-qty">{{ prod.product_quantity }} </span> x
                {{ prod.name }}
                <span
                  >${{ getPricing(prod)
                  }}<a-icon
                    type="minus-circle"
                    @click="deleteProduct(prod, index)"
                /></span>
              </div>
              <div v-if="prod.options" class="order-extra-name">
                <div
                  class="order-extra"
                  v-for="extra in prod.options.extras"
                  :key="extra.id"
                >
                  {{ extra.name }}
                  <div
                    class="picked"
                    v-for="(pick, index) in extra.extras"
                    :key="index"
                  >
                    {{ pick.name }}<span>{{ getFormatExtraCost(pick) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="xpress-footer">
            <div class="order-total" v-if="ticketStarted">
              <span>Agregar una nota a la orden:</span>
              <div class="order-notes">
                <a-textarea
                  v-model="textOrder"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </div>
              <span>Agregar una nota al repartidor:</span>
              <div class="order-notes">
                <a-textarea
                  v-model="textNotes"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </div>
              <div class="delivery-payment-type"></div>
              <div class="total">
                <a-row type="flex" class="payment-type">
                  <a-form-item style="width: 100%" class="user-data">
                    <a-select
                      default-value="2"
                      style="width: 100%"
                      @change="handleChange"
                    >
                      <a-select-option value="1">
                        Pago Tarjeta
                      </a-select-option>
                      <a-select-option value="2">
                        Pago Efectivo
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-row>
                <a-row type="flex">
                  <a-col :span="18">
                    <span>Envío</span>
                  </a-col>
                  <a-col class="total-number" :span="6">
                    <a-input-number
                      :formatter="
                        (value) =>
                          `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      v-model="totalDelivery"
                      :min="fee"
                      placeholder="Total"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex">
                  <a-checkbox
                    :checked="clientPaysDelivery"
                    @change="addDeliveryFee"
                    >Absorber envío</a-checkbox
                  >
                </a-row>
                <template v-if="clientPaysDelivery">
                  <a-row type="flex">
                    <a-col :span="18">
                      <span>Cantidad a absorber</span>
                    </a-col>
                    <a-col class="total-number" :span="6">
                      <a-input-number
                        :formatter="
                          (value) =>
                            `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        "
                        v-model="totalAbsorb"
                        :max="totalDelivery"
                        :min="0"
                        placeholder="Total"
                      />
                    </a-col>
                  </a-row>
                  <a-row type="flex">
                    <a-col :span="18">
                      <span>Envío a cobrar</span>
                    </a-col>
                    <a-col class="total-number" :span="6">
                      <span>${{ xpressDeliveryFee }}</span>
                    </a-col>
                  </a-row>
                </template>
                <a-row type="flex">
                  <a-col :flex="3">
                    <span>TOTAL</span>
                  </a-col>
                  <a-col class="total-number" :flex="3">
                    <span>${{ totalTicket + xpressDeliveryFee }}</span>
                  </a-col>
                </a-row>
              </div>
            </div>
            <a-row type="flex" class="order-footer">
              <a-button key="back" class="cancel-btn"> Cancelar </a-button>
              <a-button
                key="create"
                type="primary"
                @click="assignTime"
                :loading="placingOrder"
              >
                Continuar
              </a-button></a-row
            >
          </div>
        </div>
      </div>
    </a-col>
    <Error
      :visible="isErrorVisible"
      :message="errorMessage"
      v-on:cancel="errorVisible = false"
    />
    <AddTime
      ref="cookingTimesModal"
      :visible="addTimeVisible"
      v-on:cancel="addTimeVisible = false"
      v-on:addExtraTime="addExtraTime"
    />
  </a-row>
</template>
<script>
import PickExtras from "@/components/Xpress/orders/PickExtras";
import status from "@/utils/orderStatus.js";
import orderStatus from "@/utils/xpressStatus.js";
import Error from "@/components/modal/Error";
import errorM from "@/utils/errors.js";
import AddTime from "@/components/orders/accepted/AddTime";
import { formatNumbers, productStatus } from "@/utils/utils.js";

export default {
  name: "Menu",
  components: {
    AddTime,
    PickExtras,
    Error,
  },
  props: {
    orderTypeSelected: null,
    fee: null,
    deliveryPaymentType: null,
  },
  data() {
    return {
      addTimeVisible: false,
      selectedMenu: "",
      loaded: false,
      menu: Object,
      menuFiltered: Object,
      sections: Object,
      expandIconPosition: "left",
      option: {
        type: Object,
        default: null,
      },
      visible: false,
      searchField: "",
      product: [],
      shownProducts: [],
      addedProduct: Object,
      addedExtras: [],
      ticketStarted: false,
      textNotes: "",
      textOrder: "",
      totalTicket: 0,
      keyComponent: 0,
      placingOrder: false,
      payment: 2,
      errorVisible: false,
      errorMessage: "",
      clientPaysDelivery: true,
      totalDelivery: 0,
      totalAbsorb: 0,
    };
  },
  created() {
    this.loadSavedXpressOrder();
    this.startLoadMenu();
  },
  methods: {
    addDeliveryFee() {
      this.totalAbsorb = 0;
      this.clientPaysDelivery = !this.clientPaysDelivery;
    },
    handleChange(value) {
      this.payment = value;
    },
    loadSavedXpressOrder() {
      if (this.$store.getters.isSavedXpressOrder) {
        const savedOrder = this.$store.getters.getXpressOrder;

        this.textOrder = savedOrder.textOrder;
        this.textNotes = savedOrder.textNotes;
        this.totalTicket = savedOrder.totalTicket;
        this.product = savedOrder.product;
        this.orderTypeSelected = savedOrder.orderTypeSelected;
        this.payment = savedOrder.payment;
        this.clientPaysDelivery = savedOrder.clientPaysDelivery;
        this.totalAbsorb = savedOrder.totalAbsorb;
        this.totalDelivery = savedOrder.totalDelivery;

        this.ticketStarted = true;

        this.$message.success("Orden cargada con éxito", 5);
      }
    },
    startLoadMenu() {
      this.selectedMenu = this.$store.getters.establishmentMenu[0].name;
      this.sections = this.$store.getters.establishmentMenu;
      this.menu = this.sections.find(
        (x) => x.name === this.selectedMenu
      ).sections;
      this.menuFiltered = this.menu;
      this.loaded = true;
      this.textNotes = this.xpressNote;

      this.totalDelivery = this.fee;

      this.clientPaysDelivery = this.deliveryPaymentType !== "client_pays";
    },
    updateMenuStore(searchValue) {
      this.estMenu =
        this.$store.getters.establishmentMenu[this.getSelectedMenu] || this.$store.getters.establishmentMenu[0];
      this.selectedMenu = this.estMenu.name;
      this.sections = this.$store.getters.establishmentMenu;
      this.menu = this.sections.find(
        (x) => x.name === this.selectedMenu
      ).sections;
      this.menuFiltered = this.menu;
      if (searchValue !== "") {
        this.onSearch(searchValue);
      }

      return this.menuFiltered;
    },
    showModal(section, item) {
      item.section = section;
      this.item.data = item;
      this.item.show = true;
    },
    updateTotalCostExtras(extras, type, units) {
      if (extras) {
        extras.forEach(function (extra) {
          extra.extras.forEach(function (product) {
            const extraCost = this.getExtraCost(product) || 0;
            if (type === 0) {
              this.totalTicket += units * extraCost;
            } else {
              this.totalTicket -= units * extraCost;
            }
          }, this);
        }, this);
      }
    },
    deleteProduct(product, index) {
      this.product.splice(index, 1);
      this.totalTicket -= product.product_quantity * this.getPricing(product);

      // Remove cost of extras
      this.updateTotalCostExtras(product.options.extras, 1, product.product_quantity);
    },
    selectExtras(product) {
      if (this.hasExtras(product) || !this.isProductWithPrice(product)) {
        this.addedProduct = {
          ...product,
        };
        this.option = {
          ...this.option,
          options: product.options,
          product: product,
        };
        this.keyComponent += 1;
        this.visible = true;
      } else {
        this.addItemToCartNoExtras(product);
        this.ticketStarted = true;
      }
    },
    addItemToCartNoExtras(product) {
        let productAdded = this.product.find((p) => p.id === product.id);
        if (typeof productAdded !== "undefined") {
          productAdded.product_quantity = productAdded.product_quantity + 1;
        } else {
          product = {
            ...product,
            product_quantity: 1,
          };
          this.product.push(product);
          this.shownProducts.push(product);
        }
        this.totalTicket += productStatus.getPricing(product);
        this.ticketStarted = true;
      },
    hasExtras(product) {
      return product.options.length > 0;
    },
    isProductWithPrice(product) {
      return productStatus.isProductWithPrice(product);
    },
    productAvailable(product) {
      return product.available > 0;
    },
    addExtras(extras) {
      this.addedExtras = extras;
      this.ticketStarted = true;

      const units = extras.totalUnits || 1;

      this.addedProduct = {
        ...this.addedProduct,
        product_quantity: units,
      };

      if (!this.isProductWithPrice(this.addedProduct)) {
        this.addedProduct.selectedQty = extras.selectedQty;
      }

      let product = this.addedProduct;

      // Add cost of extras to ticket total
      this.totalTicket += (units * productStatus.getPricing(this.addedProduct));
      this.updateTotalCostExtras(extras.extras, 0, product.product_quantity);

      // Remove options from product
      delete product.options;

      (product.options = this.addedExtras), this.product.push(product);
      this.shownProducts.push(product);
    },
    getPricing(product) {
      if (
        orderStatus.type[this.orderTypeSelected] === orderStatus.type.XPRESS
      ) {
        return productStatus.getPricing(product);
      }
      return product.pricing;
    },
    getExtraCost(extra) {
      return formatNumbers.getExtraCost(extra, this.isElectronXpress);
    },
    getFormatExtraCost(extra) {
      const eCost = this.getExtraCost(extra);
      return  eCost ? `$${eCost}` : "--";
    },
    assignTime() {
      if (this.product.length > 0) {
        this.addTimeVisible = true;
      } else {
        this.errorMessage = errorM.orders["EMPTY_ORDER"];
        this.errorVisible = true;
      }
    },
    isXpress() {
      return typeof this.selectedOrder.type !== "undefined";
    },
    async addExtraTime(time) {
      try {

        this.addTimeVisible = false;

        this.createOrder(time)
      } catch (err) {
        console.log(err);
      }
    },
    async createOrder(time) {
      if (this.product.length > 0) {
        this.addTimeVisible = false;

        this.placingOrder = true;

        let order = {
          cooking_time: time,
          establishment_id: this.$store.getters.establishmentId,
          xpress_client_id: this.$store.getters.userXpress.id,
          xpress_address_id: this.xpressAddress.id,
          status: status.response.ACCEPT,
          comments: this.textOrder,
          delivery_comments: this.textNotes,
          total: this.totalTicket,
          items: this.product,
          type: orderStatus.type[this.orderTypeSelected],
          payment_method_id: this.payment,
          delivery_payment_type: this.clientPaysDelivery
            ? "establishment_pays"
            : "client_pays",
          establishment_shipping_cost: this.totalAbsorb,
          shipping_cost: this.totalDelivery,
        };

        const resp = await this.$store.dispatch("post", {
          route: "xpress_order",
          data: order,
        });
        if (resp.result) {
          // Remove xpress order from on going order
          this.$store.commit("set_current_saved_xpress_order", null);

          let activeOrders = this.$store.getters.getActiveOrders;
          activeOrders.push(resp.order);
          this.$store.commit("set_orders_active", activeOrders);

          this.product = [];
          this.$message.success("Orden creada con éxito", 5);
          this.totalTicket = 0;
          this.$store.commit("auth_userXpress", null);
          this.$router.push("/order-active");
        } else {
          if (resp.error) {
            this.errorMessage = errorM.orders[resp.code];
            this.errorVisible = true;
          }
        }
        this.placingOrder = false;
      } else {
        this.errorMessage = errorM.orders["EMPTY_ORDER"];
        this.errorVisible = true;
      }

      this.$refs.cookingTimesModal.resetModalOkButton();
    },
    changedMenu(value) {
      this.$store.commit("set_selected_menu", value);
      //let menu = this.sections.find((x) => x.name === value);
      //this.menu = typeof menu !== "undefined" ? menu.sections : "undefined";
    },
    loadMenu() {
      this.updateMenuStore("");
      this.loaded = true;
    },
  },
  computed: {
    isElectronXpress() {
      return orderStatus.type[this.orderTypeSelected] === orderStatus.type.XPRESS;
    },
    xpressAddress() {
      return this.$store.getters.userXpress.address;
    },
    xpressDeliveryFee() {
      return this.totalDelivery - this.totalAbsorb;
    },
    xpressNote() {
      return this.xpressAddress.notes;
    },
    isPickExtrasVisible() {
      return !this.$store.getters.isNewOrder && this.visible;
    },
    isErrorVisible() {
      return !this.$store.getters.isNewOrder && this.errorVisible;
    },
    isEmptyMenu() {
      return this.menuEstablishment.length == 0;
    },
    menuEstablishment() {
      return this.updateMenuStore(this.searchField);
    },
    getSelectedMenu() {
      return this.$store.getters.getSelectedMenu;
    },
  },
  updated() {
    const xpressOrder = {
      textOrder: this.textOrder,
      textNotes: this.textNotes,
      totalTicket: this.totalTicket,
      product: this.product,
      orderTypeSelected: this.orderTypeSelected,
      payment: this.payment,
      clientPaysDelivery: this.clientPaysDelivery,
      totalAbsorb: this.totalAbsorb,
      totalDelivery: this.totalDelivery
    }

    this.$store.commit("set_current_saved_xpress_order", xpressOrder);
  },
};
</script>
