var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"modal-user pick-extras",attrs:{"title":_vm.productName,"maskClosable":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.hasProduct)?_c('div',{staticClass:"product-descriptions"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"add-units",attrs:{"type":"flex"}},[_c('p',[_vm._v(_vm._s(_vm.productDescription)+" ")]),(_vm.hasExtras)?[_vm._v(" → "),_c('div',{staticClass:"tag-units"},[_vm._v("Unidades")]),_c('a-icon',{attrs:{"type":"minus-circle-o"},on:{"click":function($event){return _vm.editUnits(0)}}}),_c('div',{staticClass:"total-units"},[_c('span',[_vm._v(_vm._s(_vm.totalUnits))])]),_c('a-icon',{attrs:{"type":"plus-circle-o"},on:{"click":function($event){return _vm.editUnits(1)}}})]:_vm._e()],2),(_vm.hasProduct && !_vm.isProductWithPrice)?_c('div',{staticClass:"product-selected-qty"},[_c('a-form-item',{attrs:{"label":"Precio"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'selected_qty',
              {
                rules: [
                  {
                    required: true,
                    message: "El campo precio es requerido.",
                  } ],
              } ]),expression:"[\n              'selected_qty',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: `El campo precio es requerido.`,\n                  },\n                ],\n              },\n            ]"}],attrs:{"min":1,"max":1000,"placeholder":"Introduce el precio","formatter":function (value)  { return ("$ " + value).replace(/\./g, ''); },"step":1}})],1)],1):_vm._e(),_c('div',{staticClass:"product-options"},_vm._l((_vm.option.product.options),function(extra){return _c('div',{key:extra.id,staticClass:"option"},[(_vm.isUnique(extra.list))?_c('a-form-item',{attrs:{"label":extra.list.description}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("radio" + (extra.list.id)),
                {
                  rules: [
                    {
                      required: _vm.isRequired(extra.list),
                      message: ("El campo " + (extra.list.description) + " es requerido."),
                    } ],
                } ]),expression:"[\n                `radio${extra.list.id}`,\n                {\n                  rules: [\n                    {\n                      required: isRequired(extra.list),\n                      message: `El campo ${extra.list.description} es requerido.`,\n                    },\n                  ],\n                },\n              ]"}]},_vm._l((extra.list.extras),function(details){return _c('div',{key:details.id,staticClass:"option-details"},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('p',[_vm._v(_vm._s(details.name))])]),_c('a-col',{staticClass:"option-cost",attrs:{"span":12}},[_c('div',[_c('a-radio',{attrs:{"disabled":_vm.isNotAvailable(details),"value":details.name},on:{"change":function($event){return _vm.addExtra(
                            $event,
                            extra.list,
                            details,
                            0,
                            ("radio" + (extra.list.id))
                          )}}},[_c('p',[_vm._v(_vm._s(_vm.getExtraCost(details)))])])],1)])],1)],1)}),0)],1):_c('a-form-item',{attrs:{"label":extra.list.description}},[_c('p',{staticClass:"option-limit"},[_vm._v(" Seleccione entre "+_vm._s(_vm.getMinExtra(extra.list))+" y "+_vm._s(extra.list.limit)+" opciones ")]),_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("checkbox" + (extra.list.id)),
                {
                  rules: [
                    {
                      required: _vm.isRequired(extra.list),
                      message: ("El campo " + (extra.list.description) + " es requerido."),
                    } ],
                } ]),expression:"[\n                `checkbox${extra.list.id}`,\n                {\n                  rules: [\n                    {\n                      required: isRequired(extra.list),\n                      message: `El campo ${extra.list.description} es requerido.`,\n                    },\n                  ],\n                },\n              ]"}]},_vm._l((extra.list.extras),function(details){return _c('div',{key:details.id,staticClass:"option-details"},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('p',[_vm._v(_vm._s(details.name))])]),_c('a-col',{staticClass:"option-cost",attrs:{"span":12}},[_c('div',[_c('a-checkbox',{attrs:{"disabled":_vm.isNotAvailable(details) ||
                            (_vm.getComboboxDisabledStatus(extra.list) &&
                              !_vm.isSelected(
                                ("checkbox" + (extra.list.id)),
                                details.id
                              )),"value":details.id},on:{"change":function($event){return _vm.addExtra(
                            $event,
                            extra.list,
                            details,
                            1,
                            ("checkbox" + (extra.list.id))
                          )}}},[_c('p',[_vm._v(_vm._s(_vm.getExtraCost(details)))])])],1)])],1)],1)}),0)],1)],1)}),0)],1)],1):_vm._e(),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.goBack}},[_vm._v(" Atrás ")]),_c('a-button',{key:"create",attrs:{"type":"primary"},on:{"click":_vm.submitAddData}},[_vm._v(" Agregar ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }